import Vue from 'vue';
import App from './App.vue';
import router from './router';
import common from './assets/common';
import VueHead from 'vue-head';
import VueScrollTo from 'vue-scrollto';
import 'spectre.css/dist/spectre.min.css';
import 'spectre.css/dist/spectre-exp.min.css';
// import 'spectre.css/dist/spectre-icons.min.css';

Vue.use(common);
Vue.use(VueHead);
Vue.use(VueScrollTo)
Vue.config.productionTip = false;

// Components
import scrollTrigger from './components/scrollTrigger';
Vue.component('scrollTrigger', scrollTrigger);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
